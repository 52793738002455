<template>
  <div class="wrapper d-block align-items-center w-100">
    <div v-for="(button, index) of buttons" :key="index" class="d-inline-block">
      <router-link :to="button.href">
        <ion-button :style="style">
          {{ button.label }}
        </ion-button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Button } from '../types';

const props = defineProps({
  buttons: {
    type: Array,
    default: () => [],
  },
  style: {
    type: Object,
    default: () => ({}),
  },
});

const buttons = computed<Button[]>(() => toRef(props.buttons).value as Button[]);
</script>

<style lang="sass" scoped>
</style>
